/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $(".sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        $("html").click(function () {
          if (removeClass) {
            $(".hamburger").removeClass('is-active');
            $(".sideNav").removeClass('sideNav-open');
            $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        });

        // add scrollup button for the long pages
        jQuery(document).ready(function () {
          jQuery(window).scroll(function () {
            if (jQuery(this).scrollTop() > 100) {
              jQuery('.scrollup').fadeIn();
            } else {
              jQuery('.scrollup').fadeOut();
            }
          });
          jQuery('.scrollup').click(function () {
            jQuery("html, body").animate({
              scrollTop: 0
            }, 600);
            return false;
          });
        });

        // add animation to dropdown menu
        $('.dropdown').on('show.bs.dropdown', function(e){
          $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
        });
        $('.dropdown').on('hide.bs.dropdown', function(e){
          $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
        });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



// Google Maps API
function initialize_gmap(map_lat, map_long, point_lat, point_long, address, title, directions) {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  var isDraggable = w > 768 ? true : false;
  var point = new google.maps.LatLng(point_lat, point_long);

  var settings = {
    zoom: 15,
    center: {lat: map_lat, lng: map_long},
    scrollwheel: false,
    draggable: isDraggable,
    mapTypeControl: true,
    mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
    navigationControl: true,
    navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
    mapTypeId: google.maps.MapTypeId.ROADMAP};

  var map = new google.maps.Map(document.getElementById("map_canvas"), settings);

  var contentString = '<div id="mapContent" style="text-align:left;">'+
    '<div id="siteNotice">'+
    '</div>'+
    '<a href="' + directions + '" target="_blank"><h4>' + title + '<br><small>(Click for directions)</small></h4></a>'+
    '<div id="bodyContent">'+
    '<p>' + address + '</p>'+
    '</div>'+
    '</div>';
  var infowindow = new google.maps.InfoWindow({
    content: contentString
  });

  var companyMarker = new google.maps.Marker({
    position: point,
    map: map,
    title: title});

    infowindow.open(map,companyMarker);
  }